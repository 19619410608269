<template>
  <div class="tab-list-ads-accounts">
    <div class="form-action">
      <!-- filter desktop -->
      <div class="filter-desktop balance-allowcation">
        <!-- filter 1 -->
        <b-row class="input-control">
          <b-col
            md="4"
            lg="5"
          >
            <div>
              <b-form-group class="m-0">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-form-select
                      v-model="searchSelected"
                      class="select-search-custom input-height"
                      :options="searchOptions"
                      @input="handleChangeSearchType"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model.trim="searchTerm"
                    :placeholder="
                      searchSelected === 'email'
                        ? $t('partnerView.placeholderSearchByAdsEmail')
                        : $t('partnerView.placeholderSearchByAdsId')
                    "
                    :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                    class="d-inline-block input-height"
                    @input="handleSearch"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
          <!-- <b-col
            offset-lg="1"
            md="5"
            lg="3"
            class="pl-1 d-flex justify-content-end input-height"
          >
            <calendar
              v-model="rangeDate"
              @input="onSelect"
            />
          </b-col> -->
          <!-- <b-col
            md="3"
            class="px-1"
          >
            <b-form-group>
              <b-form-select
                v-model="statusSelected"
                :options="statusOptions"
                class="input-height"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
      </div>
      <!-- /filter desktop -->

      <!-- table -->
      <vue-good-table
        class="vgt-no-border table-member-ads"
        mode="remote"
        :columns="columns"
        :rows="listAdsAccount"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: totalMemberAds > 0,
        }"
        :sort-options="{
          enabled: false
        }"
        :is-loading="loading"
        @on-sort-change="onSortChange"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: _id -->
          <div
            v-if="props.column.field === '_id'"
            class="d-flex align-items-center cursor-pointer"
          >
            <b-img
              class="ads-avt-img"
              :src="adsAccountImg"
            />
            <div class="d-flex flex-column">
              <p class="text-primary font-weight-600 mb-0">
                {{ props.row.name || '' }}
              </p>
              <span class="text-placeholder">Id: {{ props.row._id || '' }}</span>
            </div>
          </div>

          <!-- Column: partnerBCId -->
          <div
            v-else-if="props.column.field === 'partnerBCId'"
            class="d-flex align-items-center cursor-pointer"
          >
            <b-img
              rounded
              class="ads-avt-img"
              :src="baAdsAccountImg"
            />
            <div class="d-flex flex-column">
              <span class="text-placeholder">Id: {{ props.row.partnerBCId }}</span>
            </div>
          </div>

          <!-- Column: status -->
          <div
            v-else-if="props.column.field === 'displayTimezone'"
            class="d-flex align-items-center"
          >
            <span
              v-if="props.row.displayTimezone"
            >
              {{ props.row.displayTimezone }}
            </span>
            <span v-else>
              {{ props.row.timezone }}
            </span>
          </div>

          <!-- Column: status -->
          <div
            v-else-if="props.column.field === 'status'"
            class="d-flex align-items-center"
          >
            <span
              class="text-nowrap status-member"
              :class="styleStatus(props.row.status)"
            >
              {{ statusAdsAccount(props.row.status) }}
            </span>
          </div>

          <!-- Column: requestedAt -->
          <span v-else-if="props.column.field === 'assignAdsAccount.at'">
            <div
              v-if="
                props.row.assignAdsAccount && props.row.assignAdsAccount.at
              "
            >
              {{ createdDate(props.row.assignAdsAccount.at) }}
            </div>
          </span>

          <!-- Column: Actions -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <span> Remove </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalMemberAds > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> of {{ totalMemberAds }} ads account </span>
            </div>
            <div>
              <b-pagination
                :total-rows="totalMemberAds"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0pagination-table"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>

        <div
          slot="emptystate"
          class="text-center"
        >
          <div v-if="errorGetListAds">
            <p class="text-danger">
              {{ errorGetListAds }}
            </p>
          </div>
          <div v-else>
            <no-data-table-image class="my-5" />
          </div>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BFormGroup,
  BSpinner,
  BImg,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import { createNamespacedHelpers } from 'vuex'
import { ADS_ACCOUNT_STATUS } from '@/constants'
import { toastification } from '@core/mixins/toast'
import _get from 'lodash/get'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BSpinner,
    BImg,
    BPagination,

    VueGoodTable,
    NoDataTableImage,
  },

  mixins: [toastification, envMixin],

  props: {
    errorGetListAds: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // search Dropdown
      searchSelected: 'id',
      searchOptions: [
        {
          text: this.$t('partnerView.labelAdsID'),
          value: 'id',
        },
        // {
        //   text: 'Ads Name',
        //   value: 'adsAccountName',
        // },
      ],

      // status filter
      statusSelected: null,
      statusOptions: [
        {
          text: 'All status',
          value: null,
        },
        {
          text: 'Approved',
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED,
        },
        {
          text: 'In Review',
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW,
        },
        {
          text: 'Not Approved',
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED,
        },
        {
          text: 'Punished',
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED,
        },
      ],

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      // date picker
      rangeDate: {
        startDate: null,
        endDate: null,
      },

      columns: [
        {
          label: this.$t('partnerView.textUserInfo'),
          field: '_id',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.businessAccount'),
          field: 'partnerBCId',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.modalCreateAdAccount.labelTimezone'),
          field: 'displayTimezone',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.currency'),
          field: 'currency',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.status'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textCreateAt'),
          field: 'assignAdsAccount.at',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['memberAds', 'memberDetail', 'loading', 'message', 'status']),

    listAdsAccount() {
      return _get(this.memberAds, ['content'], [])
    },

    totalMemberAds() {
      return _get(this.memberAds, ['paging', 'total'], 0)
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    statusAdsAccount() {
      const statusAds = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: this.$t('myAdAccountsPage.textApproved'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: this.$t('myAdAccountsPage.textInReview'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: this.$t('myAdAccountsPage.textNotApproved'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: this.$t('partnerView.statusPunished'),
      }

      return status => statusAds[status]
    },

    styleStatus() {
      const statusAds = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: 'approved',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: 'in-review',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: 'not-approved',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: 'punished',
      }

      return status => statusAds[status]
    },

    rangeDates() {
      return {
        from: this.rangeDate.startDate,
        to: this.rangeDate.endDate
          ? this.rangeDate.endDate
          : this.rangeDate.startDate,
      }
    },

    adsAccountImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/common/ic-ads-acc-emedia.svg')
      }
      return require('@/assets/images/common/ic-ads-acc.svg')
    },

    baAdsAccountImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/common/ic-ba-acc-emedia.svg')
      }
      return require('@/assets/images/common/ic-ba-acc.svg')
    },
  },

  methods: {
    ...mapActions(['getListMemberAds']),

    async fetchMemberAdsManagement() {
      const params = {
        id: this.$route.params.id,
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { keyword: this.searchTerm }),
        // ...(this.planSelected && { planType: this.planSelected }),
        // ...(this.statusSelected && { status: this.statusSelected }),
        ...this.rangeDates,
      }

      await this.getListMemberAds(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    onSelect(val) {
      if (val) {
        this.fetchMemberAdsManagement()
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      // this.fetchListMemberPartner()
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.clearSearch()
      } else if (searching.length > 2) {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchMemberAdsManagement()
        }, 600)
      }
    },

    clearSearch() {
      this.searchTerm = ''
      this.currentPage = 1
      this.fetchMemberAdsManagement()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchMemberAdsManagement()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      // await this.fetchListMemberPartner()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchMemberAdsManagement()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchMemberAdsManagement()
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-action {
  .currency {
    .vs__dropdown-toggle {
      height: 100%;
      border-radius: 12px;
    }
  }

  .product-tag {
    .b-form-tags {
      padding: 0;
      border: none;
    }

    .card {
      border-radius: 0.625rem;
    }

    .card-body {
      padding: 0.86rem 1rem;
    }
  }

  .country-tag {
    .b-form-tags {
      padding: 0;
      border: none;
    }

    .vs__selected {
      background: rgba(22, 33, 62, 0.05);
      color: rgba(22, 33, 62, 0.7);
    }

    .vs__dropdown-option--highlight {
      background: rgba(22, 33, 62, 0.05);
      color: rgba(22, 33, 62, 0.7);
    }
  }

  .input-height {
    .vs__dropdown-toggle {
      border: 1px solid #e0e0e5;
    }
  }
}
</style>
<style lang="scss" scoped>

.table-member-ads{
  margin-top: 24px;
}
  .input-height{
    height: 40px;
  }
  .footer-tab {
  padding-top: 27px;
}

.status-member{
  padding: 6px 15.5px;
  text-transform: uppercase;
  border-radius: 100px;
  width: max-content;
}

.approved{
  background: #1DB9C31A;
  color: #1DB9C3;
}

.in-review{
  background: #FBB03B1A;
  color: #FBB03B
}

.not-approved{
  background: #E11C4E1A;
  color: #E11C4E;
}

.punished{
  background: #1DB9C31A;
  color: #1DB9C3
}
</style>
