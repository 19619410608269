<template>
  <div class="tab-list-ads-accounts">
    <div class="form-action">

      <div v-if="hasAcl">
        <div>
          <validation-observer
            ref="formValidation"
            v-slot="{ invalid }"
          >
            <b-form>
              <div
                class="bg-white box"
                :class="{
                  'p-1': $device.mobile,
                  'form-add-product-link': !$device.mobile,
                }"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="input-form"
                  >
                    <b-form-group
                      class="product-tag"
                      label-for="tags-validation"
                    >
                      <div class="d-flex justify-content-between">
                        <label class="form-label">
                          {{ $t('myAdAccountsPage.textProductUrl') }} <span class="text-danger">* </span>
                          <img
                            v-b-tooltip.v-light="{
                              title:
                                `<div>- ${$t('myAdAccountsPage.firstContentTooltips')} <br/>- ${$t('myAdAccountsPage.secondContentTooltips')}</div>`,
                              html: true,
                            }"
                            :src="require('@/assets/images/common/ic-help.svg')"
                            alt="image"
                            style="margin-top: -10px"
                          >
                        </label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        id="fullName"
                        :name="$t('myAdAccountsPage.inReviewTab.labelProductLink')"
                        rules="required|url|productLink"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            v-model.trim="productsData.productURL"
                            name="product link"
                            class="input-height input-product-link"
                            :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderProductLink')"
                            :tabindex="1"
                            :state="errors.length > 0 ? false : null"
                            @focus="onFocus"
                            @blur="onBlur"
                          />
                        </b-input-group>
                        <div
                          class="notices-product-link"
                          :class="{
                            'input-invalid' : errors.length > 0 && !isFocus,
                            'input-focus' : isFocus
                          }"
                        >
                          <span class="title-notices">🔥🔥🔥 {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textNotices') }}:</span>
                          <ul class="list-notices">
                            <li class="item-notices">
                              <span
                                class="content-notices"
                              >{{ $t('productLinkPage.firstNoticesInputProductLink') }}</span>
                            </li>
                            <li class="item-notices">
                              <span
                                class="content-notices"
                              >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentSecondNotices') }}
                                <a
                                  href="https://www.google.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  class="text-blue"
                                >https://www.google.com</a>
                                {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIsDifferentFrom') }}
                                <a
                                  href="https://google.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  class="text-blue"
                                >https://google.com</a>. {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textInCase') }}</span>
                            </li>
                            <li class="item-notices">
                              <span
                                class="content-notices"
                              >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentFinalNotices') }}</span>
                            </li>
                          </ul>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <validation-provider
                      #default="{}"
                      :name="$t('myAdAccountsPage.labelTargetMarket')"
                      rules="required"
                    >
                      <b-form-group class="m-0 country-tag">
                        <div class="d-flex justify-content-between">
                          <label class="form-label">
                            {{ $t('myAdAccountsPage.labelTargetMarket') }} <span class="text-danger">*</span>
                            <span class="font-small-2 text-placeholder">
                              ({{ $t('myAdAccountsPage.contentSubTargetMarket') }})</span>
                          </label>
                        </div>
                        <v-select
                          v-model="productsData.targetMarkets"
                          class="input-height"
                          multiple
                          :options="availableOptions"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <p
                      class="font-14 text-notes"
                    >
                      *{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentNotesStep',{platform: `${ platformName }`}) }}
                    </p>
                  </b-col>
                </b-row>
                <b-row class="mt-2 text-right">
                  <b-col>
                    <div class="btn-control">
                      <btn-loading
                        variant-convert="btn-submit"
                        pill
                        :loading="loadingPartnerShip"
                        :disabled="invalid"
                        @click="submit"
                      >
                        {{ $t("forgotPW.btnSendLinkReset") }}
                      </btn-loading>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        class="vgt-no-border table-product-links"
        mode="remote"
        :columns="columns"
        :rows="listProductLink"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: false
        }"
        :sort-options="{
          enabled: false
        }"
        :is-loading="loadingReferral"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: productUrl -->
          <div
            v-if="props.column.field === 'productUrl'"
            class="d-flex align-items-center cursor-pointer"
          >
            <div class="productUrl">
              <span
                class="url"
                @click.prevent="goToUrl(props.row.productUrl)"
              >
                {{ props.row.productUrl }}
              </span>
            </div>
          </div>

          <!-- Column: targetMarkets -->
          <div
            v-else-if="props.column.field === 'targetMarkets'"
            class="d-flex align-items-center"
          >
            <div v-if="props.row.targetMarkets">
              <div
                v-for="(item, index) in props.row.targetMarkets"
                :key="index"
                class="target-markets"
              >
                <span class="item-target-market">
                  {{ item }}
                </span>
              </div>
            </div>
            <div v-else>
              <p class="mb-0">
                N/A
              </p>
            </div>
          </div>

          <!-- Column: requestedAt -->
          <div
            v-else-if="props.column.field === 'requestedAt'"
            class="d-flex align-items-center"
          >
            <div v-if="props.row.requestedAt">
              <span>
                {{ createdDate(props.row.requestedAt) }}
              </span>
            </div>
            <div v-else>
              <p class="mb-0">
                N/A
              </p>
            </div>
          </div>

          <!-- Column: rejectedReason -->
          <div
            v-else-if="props.column.field === 'rejectedReason'"
            class="d-flex align-items-center"
          >
            <div v-if="props.row.rejectedReason">
              <div v-html="props.row.rejectedReason" />
            </div>
            <div v-else>
              <p class="mb-0">
                N/A
              </p>
            </div>
          </div>

          <!-- Column: status -->
          <div
            v-else-if="props.column.field === 'status'"
            class="d-flex align-items-center"
          >
            <div
              v-if="props.row.status"
              class="status-product-links"
              :class="styleStatus(props.row.status)"
            >
              <p class="mb-0">
                {{ statusProductLinks(props.row.status) }}
              </p>
            </div>
          </div>

          <!-- Column: Actions -->
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :disabled="props.row.status === 'approved'"
                  @click.prevent="approveProductLink(props.row.productUrl)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CheckCircleIcon"
                  />
                  <span> {{ $t('partnerView.btnApprove') }} </span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="props.row.status === 'rejected'"
                  @click="openRejectProductLink(props.row)"
                >
                  <feather-icon
                    icon="ThumbsDownIcon"
                    class="mr-50"
                  />
                  <span> {{ $t('partnerView.statusReject') }} </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalProductLink > 0"
          slot="pagination-bottom"
        />

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>

        <div
          slot="emptystate"
          class="text-center"
        >
          <no-data-table-image class="my-5" />
        </div>
      </vue-good-table>
    </div>

    <reject-product-link
      ref="modal-reject-product-link"
      :member-info="memberInfo"
    />
  </div>
</template>
<script>
import {
  BSpinner,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  VBTooltip,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { PROFILE_STATUS } from '@/constants'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { toastification } from '@core/mixins/toast'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import envMixin from '@/mixins/envMixin'
import vSelect from 'vue-select'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import RejectProductLink from './RejectProductLinkModal.vue'

const { mapActions } = createNamespacedHelpers('referralProgram')
const { mapActions: mapActionsPartnerShip } = createNamespacedHelpers('partnerShip')

export default {
  components: {
    BSpinner,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BtnLoading,

    VueGoodTable,
    NoDataTableImage,
    RejectProductLink,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [stepGuideMixin, toastification, envMixin, generalConfigsMixin],

  data() {
    return {
      // search Dropdown
      searchSelected: 'localUserId',
      memberInfo: {},

      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },

      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      statusOptions: [
        {
          name: 'All status',
          value: '',
        },
        {
          name: 'Active',
          value: 'active',
        },
        {
          name: 'Block',
          value: 'block',
        },
      ],

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      isFocus: false,

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      // date picker
      rangeDate: {
        startDate: null,
        endDate: null,
      },

      // status filter
      statusSelected: {
        name: 'All status',
        value: '',
      },
      columns: [
        {
          label: this.$t('layout.productLinks'),
          field: 'productUrl',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('myAdAccountsPage.labelTargetMarket'),
          field: 'targetMarkets',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textCreateAt'),
          field: 'requestedAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.labelReasonsReject'),
          field: 'rejectedReason',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-reason-rejected',
        },
        {
          label: this.$t('balance.textStatus'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('common.textActions'),
          field: 'actions',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      memberAds: 'referralProgram/memberAds',
      memberDetail: 'referralProgram/memberDetail',
      loadingReferral: 'referralProgram/loading',
      statusReferral: 'referralProgram/status',
      messageReferral: 'referralProgram/message',
      user: 'auth/user',
      statusPartnerShip: 'partnerShip/status',
      messagePartnerShip: 'partnerShip/message',
      loadingPartnerShip: 'partnerShip/loading',
    }),

    availableOptions() {
      return this.listTargetMarket?.filter(
        opt => this.productsData?.targetMarkets?.indexOf(opt) === -1,
      )
    },

    hasAcl() {
      return this.user?.data?.roles?.find(role => role === 'VERIFY_USER_INFORMATION')
    },

    listProductLink() {
      const listProductUrl = this.memberDetail?.products?.productURLs || {}

      const arrUrlConverted = Object.keys(listProductUrl).map(value => ({
        productUrl: value,
        ...listProductUrl[value],
      }))

      return arrUrlConverted || []
    },

    totalProductLink() {
      return this.listProductLink?.length
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    statusProductLinks() {
      const statusProductLink = {
        [PROFILE_STATUS.APPROVED]: this.$t('myAdAccountsPage.textApproved'),
        [PROFILE_STATUS.REVIEWING]: this.$t('productLinkPage.statusReviewing'),
        [PROFILE_STATUS.REJECTED]: this.$t('billingPage.statusRejected'),
        [PROFILE_STATUS.NOT_FOUND]: this.$t('productLinkPage.statusNotFound'),
      }

      return status => statusProductLink[status]
    },

    styleStatus() {
      const statusProductLink = {
        [PROFILE_STATUS.APPROVED]: 'approved-status',
        [PROFILE_STATUS.REVIEWING]: 'reviewing-status',
        [PROFILE_STATUS.REJECTED]: 'rejected-status',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found-status',
      }

      return status => statusProductLink[status]
    },
  },

  methods: {
    ...mapActions(['handleProductLink', 'getMemberDetail']),
    ...mapActionsPartnerShip(['createProductLinkForMember']),

    onFocus() {
      this.isFocus = true
    },

    onBlur() {
      this.isFocus = false
    },

    resetFormProductLink() {
      this.productsData = {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      }
      this.$refs.formValidation.reset()
    },

    async approveProductLink(val) {
      const { params } = this.$route

      const paramsApprove = {
        id: params.id,
        action: 'approve',
        productURL: val,
      }
      await this.handleProductLink(paramsApprove)
      if (this.statusReferral) {
        this.toastSuccess(this.$t('partnerView.toastApprovedProductLinkSuccess'))
        this.fetchMemberDetail()
      } else {
        this.toastFailure(this.messageReferral)
      }
    },

    openRejectProductLink(val) {
      this.memberInfo = val
      this.$refs['modal-reject-product-link'].showModal()
    },

    async submit() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        const params = {
          id: this.memberDetail.localUserId,
          payload: this.productsData,
        }

        await this.createProductLinkForMember(params)
        if (this.statusPartnerShip) {
          this.toastSuccess(this.$t('partnerView.toastCreateProductLinkSuccess'))
          this.fetchMemberDetail()
          this.resetFormProductLink()
        } else {
          this.toastFailure(this.messagePartnerShip)
        }
      }
    },

    async fetchMemberDetail() {
      const params = {
        id: this.memberDetail.localUserId,
      }
      await this.getMemberDetail(params)
      if (!this.statusReferral) {
        this.toastFailure(this.messageReferral)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-action {
  .currency {
    .vs__dropdown-toggle {
      height: 100%;
      border-radius: 12px;
    }
  }

  .product-tag {
    .b-form-tags {
      padding: 0;
      border: none;
    }

    .card {
      border-radius: 0.625rem;
    }

    .card-body {
      padding: 0.86rem 1rem;
    }
  }

  .country-tag {
    .b-form-tags {
      padding: 0;
      border: none;
    }

    .vs__selected {
      background: rgba(22, 33, 62, 0.05);
      color: rgba(22, 33, 62, 0.7);
    }

    .vs__dropdown-option--highlight {
      background: rgba(22, 33, 62, 0.05);
      color: rgba(22, 33, 62, 0.7);
    }
  }

  .input-height {
    .vs__dropdown-toggle {
      border: 1px solid #e0e0e5;
      height: auto;
    }
  }
}

.table-product-links {
  .td-reason-rejected {
    min-width: 300px;

    p {
      margin: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.table-product-links {
  margin-top: 24px;
}
.productUrl {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  .url {
    text-decoration: underline;
    color: #2667ff;
  }
}

.target-markets {
  background: #16213e0d;
  padding: 6px 8px;
  margin: 0.5rem;
  border-radius: 4px;
  width: max-content;
}

// .input-height {
//   height: 40px;
// }
.footer-tab {
  padding-top: 27px;
}

.status-product-links {
  padding: 6px 15.5px;
  border-radius: 100px;
  width: max-content;
}

.approved-status {
  background: #1db9c31a;
  color: #1db9c3;
}

.reviewing-status {
  background: #fbb03b1a;
  color: #fbb03b;
}

.rejected-status {
  background: #e11c4e1a;
  color: #e11c4e;
}

.not-found-status {
  background: #1db9c31a;
  color: #1db9c3;
}

.text-notes{
  color: rgba(0, 0, 0, 0.7);
}

.form-add-product-link {
  padding: 32px;

  @media(max-width: 767px) {
    padding: 0px;
  }
}

.title-add-product-link {
  margin-bottom: 32px;
}

.btn-control {
  margin-top: 32px;
}

.input-form {
  margin-bottom: 20px;
}

.box {
  border-radius: 32px;
}

// .input-height {
//   height: 48px;
// }

.list-reason {
  li {
    margin-bottom: 5px;
  }
}

.alert-img {
  width: 18px;
}

.warning-message {
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px;
}

.input-product-link{
  border-radius: 12px 12px 0px 0px;
}

.notices-product-link{
  border: 1px solid #E8E9EC;
  border-radius: 0px 0px 12px 12px;
  border-top: none;

  padding: 14px;

  .title-notices{
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }

  .list-notices{
    margin-top: 10px;

    .item-notices{
      margin-bottom: 8px;

      .content-notices{
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &.input-invalid{
    border-color: #e11c4e;
  }

  &.input-focus{
    border-color: #16213e;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
}
</style>
