<template>
  <b-modal
    ref="modal-reject-product-link"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium text-primary font-20">
        {{ $t('partnerView.modalRejectProductLink.titleModal') }}
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="product-link-member">
      <b-card-title class="title-product-link">
        {{ $t('productLinkPage.titleColumnProductUrl') }}
      </b-card-title>
      <b-card-text
        class="content-product-link"
        @click="goToUrl(memberInfo.productUrl)"
      >
        {{ memberInfo.productUrl }}
      </b-card-text>
    </div>
    <div class="product-link-member">
      <b-row>
        <b-col md="6">
          <b-card-title class="title-product-link">
            {{ $t('myAdAccountsPage.labelTargetMarket') }}
          </b-card-title>
          <div class="content-target-market">
            <div
              v-for="(item, index) in targetMarkets"
              :key="index"
            >
              <b-card-text class="item">
                {{ item }}
              </b-card-text>
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <b-card-title class="title-product-link">
            {{ $t('balance.textStatus') }}
          </b-card-title>
          <b-card-text
            class="status-product-link"
            :class="styleStatus(memberInfo.status)"
          >
            {{ statusProductLinkTxt(memberInfo.status) }}
          </b-card-text>
        </b-col>
      </b-row>
    </div>

    <div class="form-reason">
      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <template>
            <!-- Reason -->
            <validation-provider
              #default="{ errors }"
              :name="$t('productLinkPage.titleColumnReason')"
              rules="required"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="form-label label-reason">
                    {{ $t('productLinkPage.titleColumnReason') }}
                  </label>
                </div>
                <quill-editor
                  v-model.trim="description"
                  ref="QuillEditor"
                  id="quil-content"
                  size="20"
                  :options="editorOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

            </validation-provider>
          </template>
          <!-- Form Actions -->
        </b-form>
      </validation-observer>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-2"
        type="submit"
        :disabled="description === ''"
        @click.prevent="rejectedProductLinks"
      >
        {{ $t("forgotPW.btnSendLinkReset") }}
      </b-button>
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        {{ $t('common.btnCancel') }}
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
/* eslint-disable import/no-extraneous-dependencies */

import {
  BModal,
  BButton,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'

import { toastification } from '@core/mixins/toast'
import { required } from '@validations'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import {
  PROFILE_STATUS,
  TOOLBAR_EDITOR_OPTIONS,
} from '@/constants'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import i18n from '@/libs/i18n'

const { mapGetters, mapActions } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    BModal,
    BButton,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,

    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  directives: {
    Ripple,
  },

  mixins: [numberFormatMixin, toastification],

  props: {
    memberInfo: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      description: '',

      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
        placeholder: i18n.t('partnerView.placeholderInsertTextHere'),
      },

      // validation
      required,
    }
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    targetMarkets() {
      return _get(this.memberInfo, ['targetMarkets'], [])
    },

    styleStatus() {
      const statusProductLink = {
        [PROFILE_STATUS.APPROVED]: 'approved-status',
        [PROFILE_STATUS.REVIEWING]: 'reviewing-status',
        [PROFILE_STATUS.REJECTED]: 'rejected-status',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found-status',
      }

      return status => statusProductLink[status]
    },

    statusProductLinkTxt() {
      const statusProductLink = {
        [PROFILE_STATUS.APPROVED]: this.$t('myAdAccountsPage.textApproved'),
        [PROFILE_STATUS.REVIEWING]: this.$t('productLinkPage.statusReviewing'),
        [PROFILE_STATUS.REJECTED]: this.$t('billingPage.statusRejected'),
        [PROFILE_STATUS.NOT_FOUND]: this.$t('productLinkPage.statusNotFound'),
      }

      return status => statusProductLink[status]
    },
  },

  methods: {
    ...mapActions(['handleProductLink', 'getMemberDetail']),

    hideModal() {
      this.description = ''
      this.$refs['modal-reject-product-link'].hide()
    },

    showModal() {
      this.$refs['modal-reject-product-link'].show()
    },

    async rejectedProductLinks() {
      const success = await this.$refs.refFormObserver.validate()

      if (success) {
        const { params } = this.$route
        const paramsReject = {
          id: params.id,
          action: 'reject',
          productURL: this.memberInfo.productUrl,
          rejectedReason: this.description,
        }
        await this.handleProductLink(paramsReject)
        if (this.status) {
          this.toastSuccess(this.$t('partnerView.toastRejectedProductLinkSuccess'))
          this.getMemberDetail(params)
          this.hideModal()
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
  .modal-body{
    padding: 24px;
  }

.reason-content {
  p {
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
.top-modal {
  margin-bottom: 32px;
}
.product-link-member {
  border-bottom: 1px solid rgba(22, 33, 62, 0.1);
  margin-bottom: 20px;
  .title-product-link {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .content-product-link {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .content-target-market {
    margin-bottom: 20px;
    display: flex;
    .item {
      padding: 6px 8px;
      background: rgba(61, 64, 91, 0.05);
      border-radius: 4px;
      margin-right: 6px;
    }
  }
  .status-product-link{
    text-transform:uppercase;
    margin: 0;
    border-radius: 100px;
    padding: 6px 10.5px;
    font-size: 12px;
    width: max-content;
    font-weight: 600;
  }

  .approved-status{
    background: #1DB9C31A;
    color: #1DB9C3;
  }

  .reviewing-status{
    background: #FBB03B1A;
    color: #FBB03B;
  }

  .rejected-status{
    background: #E11C4E1A;
    color: #E11C4E;
  }
}

.label-reason{
  margin-bottom: 12px;
}

.target-market-member {
  .title-target-market {
    font-size: 14px;
    margin-bottom: 12px;
  }
}
.reason-reject {
  max-width: max-content;
  width: 100%;
}

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    height: 200px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
